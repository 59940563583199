const actions = {

}
const mutations = {

}
const getters = {

}

const state = {
    projectId: 'a7cc9fde-607c-473e-8ff5-90e86d81f9b6',
    identificationActivity: '09a565c4-73f8-483d-b59a-c3e5791f9a16'
};

export default {
  namespaced: true,
  state,
    actions,
    mutations,
    getters
}
